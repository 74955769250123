<template>
  <div ref="ElectronicVoucher" class="ElectronicVoucher">
    <div ref="functionButtons" class="function-buttons"></div>
    <div ref="form" class="form-area">
      <el-form
        :inline="true"
        :model="form"
        class="demo-form-inline"
        label-position="left"
        label-width="85px"
      >
        <el-form-item label="发车时间起：">
          <el-date-picker
            v-model="form.departureBeginAt"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择时间"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="发车时间止：">
          <el-date-picker
            v-model="form.departureEndAt"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择时间"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="所属公司：">
          <companySelect
            :clearable="true"
            :company-tree="companyList"
            :valuename="valuename"
            :value="value"
            @getValue="getGroupId"
          ></companySelect>
        </el-form-item>
        <el-form-item label="车牌号：">
          <el-autocomplete
            v-model.trim="form.licensePlateNumber"
            :fetch-suggestions="querySearchAsync"
            clearable
            placeholder="请输入车牌号"
          ></el-autocomplete>
        </el-form-item>
        <el-form-item label="状态：">
          <el-select v-model="form.status" clearable placeholder="请选择状态">
            <el-option
              v-for="item in statusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="queryFun"
            >查询</el-button
          >
          <el-button type="primary" size="small" @click="exportFun"
            >导出</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <Table
      :table-data="tableData"
      :title-name="titleName"
      :col-class-type="true"
      :table-height="TableHeight"
    ></Table>
    <div class="pagination">
      <el-pagination
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { lastTime } from "@/tools/getTime.js";
import {
  getCompanyTree,
  ElectronicVoucherExpAPI,
  ElectronicVoucherListAPI,
  queryItsListAPI,
} from "@/api/lib/api.js";
import companySelect from "@/components/treeSelect/companySelect.vue";
export default {
  components: {
    companySelect,
  },
  data() {
    return {
      form: {
        currentPage: 1,
        pageSize: 10,
        departureBeginAt: "",
        departureEndAt: "",
        companyIds: "",
        licensePlateNumber: "",
        status: "",
      },
      exportForm: {},
      value: null,
      valuename: null,
      companyList: [],
      carNumber: [],
      TableHeight: 0,
      statusList: [
        {
          value: 0,
          label: "待确认",
        },
        {
          value: 1,
          label: "已确认",
        },
      ],
      tableData: [],
      titleName: [
        {
          title: " 结算号",
          props: "clearingNo",
        },
        {
          title: "所属公司",
          props: "companyName",
        },
        {
          title: "车牌号",
          props: "licensePlateNumber",
        },
        {
          title: "车牌颜色",
          props: "licensePlateColorStr",
        },
        {
          title: "班次",
          props: "banciId",
        },
        {
          title: "起点站",
          props: "startStation",
        },
        {
          title: "终点站",
          props: "endStation",
        },
        {
          title: "发车时间",
          props: "departureAt",
        },
        {
          title: "站内票",
          children: [
            {
              title: "检票口",
              props: "ticketGate",
            },
            {
              title: "票价",
              props: "inTicketPricesPer",
            },
            {
              title: "已检客票-张数",
              props: "inTicketCheckedCount",
              width: 120,
            },
            {
              title: "已检客票-金额",
              props: "inCheckedTicketAmount",
              width: 120,
            },
            {
              title: "全价票-张数",
              props: "inFullPriceTicketCount",
              width: 100,
            },
            {
              title: "全价票-金额",
              props: "inFullPriceTicketAmount",
              width: 100,
            },
            {
              title: "半价票-张数",
              props: "inHalfPriceTicketCount",
              width: 100,
            },
            {
              title: "半价票-金额",
              props: "inHalfPriceTicketAmount",
              width: 100,
            },
            {
              title: "行包-件数",
              props: "inLuggageCount",
              width: 90,
            },
            {
              title: "行包-金额",
              props: "inLuggageAmount",
              width: 90,
            },
            {
              title: "免票人数",
              props: "inFreeTicketCount",
            },
          ],
        },
        {
          title: "站外票",
          children: [
            {
              title: "票价",
              props: "inTicketPricesPer",
            },
            {
              title: "已检客票-张数",
              props: "outTicketCheckedCount",
              width: 120,
            },
            {
              title: "已检客票-金额",
              props: "outCheckedTicketAmount",
              width: 120,
            },
            {
              title: "全价票-张数",
              props: "outFullPriceTicketCount",
              width: 100,
            },
            {
              title: "全价票-金额",
              props: "outFullPriceTicketAmount",
              width: 100,
            },
            {
              title: "半价票-张数",
              props: "outHalfPriceTicketCount",
              width: 100,
            },
            {
              title: "半价票-金额",
              props: "outHalfPriceTicketAmount",
              width: 100,
            },
            {
              title: "免票人数",
              props: "outFreeTicketCount",
            },
          ],
        },
        {
          title: "营收额",
          props: "revenue",
        },
        {
          title: "车方",
          props: "carSide",
        },
        {
          title: "检票员",
          props: "tellers",
        },
        {
          title: "客安员",
          props: "guestSecurityOfficer",
        },
        {
          title: "结算凭单生成日期",
          props: "createTime",
          width: 150,
        },
        {
          title: "驾驶员确认日期",
          props: "driverConfirmationAt",
          width: 150,
        },
        {
          title: "状态",
          props: "status",
          SpecialJudgment: (res) => {
            return res == 0 ? "待确认" : "已确认";
          },
        },
      ],
      total: 0,
    };
  },
  mounted() {
    this.form.departureBeginAt = lastTime(new Date()) + " 00:00:00";
    let day = new Date();
    this.form.departureEndAt =
      day.getFullYear() +
      "-" +
      (day.getMonth() + 1) +
      "-" +
      day.getDate() +
      " 23:59:59";
    this.queryFun();
    this.getCompanyList();
    this.$nextTick(() => {
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);
      console.log(this.TableHeight);
    });
  },
  methods: {
    queryFun() {
      this.exportForm = this.deepClone(this.form);
      this.renderData();
    },
    exportFun() {
      ElectronicVoucherExpAPI(this.exportForm).then((res) => {
        const blob = new Blob([res], { type: "application/vnd.ms-excel" });
        const fileName = "电子客运凭单 " + this.GMTToStr(new Date()) + ".xlsx"; //文件名
        const linkNode = document.createElement("a");

        linkNode.download = fileName; //a标签的download属性规定下载文件的名称
        linkNode.style.display = "none";
        linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
        document.body.appendChild(linkNode);
        linkNode.click(); //模拟在按钮上的一次鼠标单击=
        URL.revokeObjectURL(linkNode.href); // 释放URL 对象
        document.body.removeChild(linkNode);
      });
    },
    renderData() {
      ElectronicVoucherListAPI(this.form).then((res) => {
        if (res.code == "SUCCESS") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    getCompanyList() {
      getCompanyTree({ parentId: sessionStorage.getItem("companyId") }).then(
        (res) => {
          if (res.code === "SUCCESS") {
            this.companyList = res.data;
          }
        }
      );
    },
    getGroupId(v) {
      if (v == null) {
        this.form.companyIds = [];
      } else {
        this.form.companyIds = [v];
      }
    },
    computeHeight() {
      const wholeHeight = this.$refs.ElectronicVoucher.clientHeight;
      this.TableHeight = wholeHeight - 270 + "px";
    },
    // 分页页数发生变化
    onCurrentChange(val) {
      this.form.currentPage = val;
      this.renderData();
    },
    // 分页change方法
    onSizeChange(val) {
      this.form.pageSize = val;
      this.form.currentPage = 1;
      this.renderData();
    },
    // 车牌号模糊查询
    querySearchAsync(queryString, cb) {
      if (queryString != "" && queryString != " ") {
        let cphArray = [];
        queryItsListAPI({ cph: queryString }).then((res) => {
          console.log(res);
          if (res.code == "SUCCESS") {
            res.data.map((item) => {
              cphArray.push({ value: item.cph, ...item });
            });
            cb(cphArray);
          }
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.ElectronicVoucher {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 4px 20px;
  box-sizing: border-box;
  background: #ffffff;
}
.Table {
  margin-top: 16px;
}
</style>
